<template>
  <div class="tab">
    <div class="box">
      <div class="top">
        <div class="item item1">
          <span>血糖异常分析</span>
        </div>
        <div class="item">
          <span>65岁老人血糖异常详情</span>
        </div>
      </div>
      <div class="bottom">
        <div class="item item1">
          <div class="chart" id="echart"></div>
        </div>
        <div class="item">
          <div class="add">
            <el-button icon="el-icon-refresh" circle></el-button>
            <el-button icon="el-icon-menu" circle></el-button>
          </div>
          <div class="table">
            <el-table :data="tableData" height="100%">
              <el-table-column prop="age" label="年龄组" :width="flexWidth('age', tableData, '年龄组')">
              </el-table-column>
              <el-table-column label="体检人数">
                <el-table-column prop="zip" label="男性" :width="flexWidth('zip', tableData, '男性')">
                </el-table-column>
                <el-table-column prop="zip" label="女性" :width="flexWidth('zip', tableData, '女性')">
                </el-table-column>
              </el-table-column>
              <el-table-column label="男性">
                <el-table-column prop="zip" label="异常人数" :width="flexWidth('zip', tableData, '异常人数')">
                </el-table-column>
                <el-table-column prop="zip" label="异常率" :width="flexWidth('zip', tableData, '异常率')">
                </el-table-column>
              </el-table-column>
              <el-table-column label="女性">
                <el-table-column prop="zip" label="异常人数" :width="flexWidth('zip', tableData, '异常人数')">
                </el-table-column>
                <el-table-column prop="zip" label="异常率" :width="flexWidth('zip', tableData, '异常率')">
                </el-table-column>
              </el-table-column>
              <el-table-column label="合计">
                <el-table-column prop="zip" label="异常人数" :width="flexWidth('zip', tableData, '异常人数')">
                </el-table-column>
                <el-table-column prop="zip" label="异常率" :width="flexWidth('zip', tableData, '异常率')">
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {},
  data () {
    return {
      tableData: [{
        zip: 200333,
        age: '65岁'
      }, {
        zip: 200333,
        age: '合计'
      }]
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.$nextTick(() => {
      this.canvas()
    })
    window.addEventListener('resize', this.getresize)
  },
  methods: {
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    getresize () {
      setTimeout(() => {
        this.myChart.resize()
      }, 500)
    },
    canvas () {
      if (!document.getElementById('echart')) return
      var chartDom = document.getElementById('echart')
      this.myChart = this.$echarts.init(chartDom)
      var app = {}
      const posList = [
        'left',
        'right',
        'top',
        'bottom',
        'inside',
        'insideTop',
        'insideLeft',
        'insideRight',
        'insideBottom',
        'insideTopLeft',
        'insideTopRight',
        'insideBottomLeft',
        'insideBottomRight'
      ]
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90
        },
        align: {
          options: {
            left: 'left',
            center: 'center',
            right: 'right'
          }
        },
        verticalAlign: {
          options: {
            top: 'top',
            middle: 'middle',
            bottom: 'bottom'
          }
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos
            return map
          }, {})
        },
        distance: {
          min: 0,
          max: 100
        }
      }
      app.config = {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance
          }
          this.myChart.setOption({
            series: [
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              },
              {
                label: labelOption
              }
            ]
          })
        }
      }
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        formatter: '{c}  {name|{a}}',
        fontSize: 16,
        rich: {
          name: {}
        }
      }
      const option = {
        color: ['#006699', '#4CABCE', '#e94949'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // y: '10%',
          data: ['合计体检人数', '男体检人数', '女体检人数'],
          textStyle: {
            fontSize: 16
          }
        },
        grid: {
          show: false,
          top: '10%',
          right: '10%',
          bottom: '10%',
          left: '10%'
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar', 'stack'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: ['65-70岁', '70-80岁', '80岁以上'],
            axisLabel: {
              show: true,
              color: '#000',
              fontSize: 14
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#000'
              }
            },
            axisLabel: {
              show: true,
              color: '#000',
              fontSize: 14
            },
            splitLine: {
              lineStyle: {
                color: '#000'
              }
            }
          }
        ],
        series: [
          {
            name: '合计体检人数',
            type: 'bar',
            barGap: '20%',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [320, 332, 301]
          },
          {
            name: '男体检人数',
            type: 'bar',
            barGap: '20%',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191]
          },
          {
            name: '女体检人数',
            type: 'bar',
            barGap: '20%',
            label: labelOption,
            emphasis: {
              focus: 'series'
            },
            data: [150, 232, 201]
          }
        ]
      }
      option && this.myChart.setOption(option)
    },
    handleSelect (key) {
      this.activeIndex = key
    },
    openTest () {
      this.showTest = true
    },
    closeTest () {
      this.showTest = false
    },
    openDetection () {
      this.showDetection = true
    },
    closeDetection () {
      this.showDetection = false
    },
    openTestDetection () {
      this.showTestDetection = true
    },
    closeTestDetection () {
      this.showTestDetection = false
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  .box {
    width: 100%;
    height: 100%;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    box-sizing: border-box;
    // border-collapse: collapse;

    .top {
      display: flex;
      width: 100%;
      height: 60px;

      .item {
        display: flex;
        align-items: center;
        width: 60%;
        height: 100%;
        padding-left: 20px;
        border-right: 1px solid #EBEEF5;
        border-bottom: 1px solid #EBEEF5;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;
      }

      .item1 {
        width: 40%;
      }
    }

    .bottom {
      display: flex;
      width: 100%;
      height: calc(100% - 60px);

      .item {
        width: 60%;
        height: 100%;
        padding: 20px;
        border-right: 1px solid #EBEEF5;
        border-bottom: 1px solid #EBEEF5;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;

        .chart {
          width: 100%;
          height: 100%;
        }

        .add {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 60px;
        }

        .table {
          width: 100%;
          height: calc(100% - 60px);

          /deep/.el-table__cell {
            text-align: center;
            color: #000;
            font-size: 16px;
          }
        }
      }

      .item1 {
        width: 40%;
      }
    }
  }
}
</style>
